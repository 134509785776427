@import '../../../../../Assets/Scss/primaryColor';

.CityLaunchersDetails {
  margin-bottom: 50px;
  .card {
    border-radius: 8px;

    .card-header {
      background: #f5f6fa;
      .title {
        font-size: 1rem;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        color: #1b1c20;

        @media (max-width: 600px) {
          margin-bottom: 10px;
        }
      }

      .btn-def-0 {
        border: 1px solid #e873a8;
        height: 34px;
        border-radius: 24px;
        font-size: 0.7rem;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        color: #ffffff;
      }

      .btn-respond-req {
        font-family: Poppins;
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        color: #00c12b;
        border: 1px solid #d9f6df;
        border-radius: 100px;
        background-color: #d9f6df;
      }
    }
  }

  .details-section {
    .card-header {
      border-bottom: 1px solid #eaecf0;
      .title {
        font-size: 0.89rem;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
      }

      .sub-title {
        font-size: 0.7rem;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: right;
      }
    }

    .btn-edit {
      border: 1px solid #e873a8;
      width: 76px;
      height: 34px;
      border-radius: 24px;
      font-family: Poppins;
      font-size: 0.7rem;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: center;
      color: #e873a8;
    }

    .card-body {
      font-size: 0.8rem;

      .value {
        font-size: 0.8rem;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: -0.30000001192092896px;
        text-align: right;
      }
      .profile-image {
        width: 100%;
        height: auto;
        border-radius: 100px;
        background: #dfe4ea;
        overflow: hidden;

        img {
          width: 100%;
        }
      }
      .seller-name {
        font-size: 0.79rem;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: -0.30000001192092896px;
      }
      .quantity {
        font-size: 0.73;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        color: #82858a;
      }
    }

    .btn-edit {
      border: 1px solid #e873a8;
      width: 76px;
      height: 34px;
      border-radius: 24px;
      font-family: Poppins;
      font-size: 0.7rem;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: center;
      color: #e873a8;
    }

    .btn-allow-debit {
      border: 1px solid #f40000;
      width: 100%;
      height: 34px;
      border-radius: 24px;
      font-family: Poppins;
      font-size: 0.7rem;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: center;
      color: #f40000;
    }

    .profile-item {
      width: 100%;
      height: 65px;
      gap: 13px;

      .name {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        color: #808080;
      }
      .value {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
      }
    }
  }

  .box_three {
    border-radius: 20px;
    background: #0e1f33;

    .title {
      font-size: 1rem;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: center;
      color: #ffffff;
      margin-top: 20px;
      border-bottom: 3px solid #e873a8;

      @media (max-width: 987px) {
        margin-left: 10px;
      }
    }
    .balance {
      font-size: 28px;
      font-weight: 600;
      line-height: 75px;
      letter-spacing: 0em;
      text-align: center;
      color: #ffffff;
    }
  }

  .user-order-list {
    thead {
      td {
        font-size: 0.9rem;
        font-weight: 500;
      }
    }
    tbody {
      tr {
        cursor: pointer;

        &:hover {
          background-color: #fafafa;
        }

        td {
          font-size: 0.8rem;
        }
      }
    }
  }

  .dropdown {
    .btn-def-0 {
      &:focus {
        color: rgb(30, 30, 30) !important;
      }
      &:hover {
        color: rgb(30, 30, 30) !important;
      }
      &:active {
        color: rgb(30, 30, 30) !important;
      }
    }
    .dropdown-item {
      &:focus {
        background-color: #eaf2ec;
      }
    }
  }
}

.amount-label {
  font-size: 0.78rem;
}
