.CityLaunchers {
  margin-bottom: 50px;

  tbody {
    tr {
      &:hover {
        cursor: pointer;
      }
      td {
        vertical-align: middle;
      }

      .accountIdCol {
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 12ch;
      }
    }
  }

  .card {
    border-radius: 8px;

    .card-header {
      background: #f5f6fa;
      .title {
        font-size: 1rem;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #1b1c20;
      }

      .search-field {
        position: relative;
        span {
          position: absolute;
          left: 10px;
          top: 8px;
        }
        .form-control {
          height: 40px;
          border: none;
          padding-left: 40px;

          &::placeholder {
            font-size: 0.8rem;
          }
        }
      }

      .dropdown-toggle::after {
        display: none;
      }
      .btn-filter {
        width: 100%;
        height: 40px;
        border-radius: 8px;
        border: none;
        background: #ffffff;
        font-size: 0.84rem;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        color: #82858a;

        @media (max-width: 764px) {
          margin-top: 10px;
        }

        svg {
          margin-right: 10px;
        }
      }
      .btn-export {
        width: 100%;
        height: 40px;
        border-radius: 8px;
        border: none;
        background: #ffffff;
        font-size: 0.84rem;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        color: #82858a;

        @media (max-width: 764px) {
          margin-top: 10px;
        }

        svg {
          margin-right: 10px;
        }
      }
    }

    .btn-add-company {
      height: 35px;
      border-radius: 50px;
      border: 1px;
      background-color: #e873a8;
      color: #ffffff;
      font-family: Poppins;
      font-size: 0.8rem;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: center;
      padding: 0 20px;
    }

    .card-body {
      border-radius: 8px;
      border: 1px solid #f4f4f7;
      background-color: #ffffff;

      thead {
        td {
          color: #808080;
          font-size: 0.8rem;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: left;
        }
      }

      tbody {
        td {
          color: #0e1f33;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: left;
        }
      }

      tbody > tr {
        border-bottom: 1px solid #eaecf0;
      }
      thead > tr {
        border-bottom: 1px solid #eaecf0;
      }

      .addrate {
        width: 105px;
        height: 25px;
        border-radius: 100px;
        border: 1px solid #e873a8;
        background-color: rgb(254, 254, 254);
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        color: #e873a8;
      }

      .default-image {
        display: inline-flex;
        width: 30px;
        height: 30px;
        border-radius: 50px;
        justify-content: center;
        align-items: center;
        background-color: #eaecf0;
        font-size: 0.6rem;
      }
    }
  }

  .option-dropdown {
    .dropdown-toggle::after {
      display: none;
    }

    .dropdown-item {
      padding: 10px 10px;
      border-bottom: 1px solid #f0f1f2;
      font-size: 0.8rem;
      &:hover {
        cursor: pointer;
      }

      a {
        text-decoration: none;
        color: #333333;
      }

      &:focus {
        background-color: #ffffff;
        color: #333333;
      }
      &:active {
        background-color: #ffffff;
        color: #333333;
      }
      &:focus-visible {
        background-color: #ffffff;
        color: #333333;
      }
    }

    .dropdown-menu {
      position: absolute;
      z-index: 999;
    }
  }

  .filter-dropdown {
    width: 328px;

    .dropdown-item {
      height: auto;

      &:hover {
        background-color: #ffffff;
      }

      label {
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        color: #333333;
        margin-bottom: 8px;
      }
      .btn-reset {
        width: 82px;
        height: 40px;
        border-radius: 50px;
        border: 1px;
        border: 1px solid #d6dee8;
        background: #ffffff;
        font-size: 0.8rem;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        color: #e873a8;
      }

      .btn-filter {
        width: 82px;
        height: 40px;
        border-radius: 50px;
        border: 1px;
        border: 1px solid #d6dee8;
        background: #e873a8;
        font-size: 0.8rem;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        color: #ffffff;
      }
    }
  }
}
