.TGPlusSubscriptionDetails {
  .card-header {
    font-size: 0.8rem;
    padding-top: 20px;
    border-bottom: 1px solid #eaecf0 !important;
  }

  .profile-image {
    width: 127px;
    height: 127px;
    border-radius: 100px;
    background: #dfe4ea;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .btn {
    font-size: 0.8rem;
    height: 37px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .profile-item {
    width: 100%;
    height: 35px;

    .name {
      font-size: 14px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: left;
      color: #808080;
    }
    .value {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: right;
      color: #000000;
    }
  }

  .status-success {
    height: 23px;
    padding: 5px 32px 5px 32px;
    border-radius: 100px;
    background: #d9f6df;
    color: #ff0000;
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
  }

  .status-danger {
    height: 23px;
    padding: 5px 32px 5px 32px;
    border-radius: 100px;
    background: #ff000026;
    color: #ff0000;
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
  }
  label {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }

  .approve-btn {
    height: 32px;
    border-radius: 50px;
    background: #e873a8;
    color: #ffffff;
  }

  .reject-btn {
    width: 151px;
    height: 35px;
    border-radius: 50px;
    border: 1px solid #fc6fbb;
    color: #fc6fbb;
  }

  .ducumentation {
    border-top: 1px solid #eaecf0 !important;
    .title {
      font-size: 0.87rem;
      color: #000000;
    }
  }

  .uploadedDocument {
    height: 100px;
    background: #e873a8;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    border-radius: 10px;

    img {
      position: absolute;
    }

    .icons {
      position: absolute;
      border-radius: 10px;
      overflow: hidden;

      .btn {
        width: 30px;
        height: 30px;
        display: flex-inline;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        padding: 0px !important;
        margin: 6px;
      }
    }
  }

  .doc-name {
    font-size: 0.8rem;
    margin-top: 8px;
  }

  .errors {
    font-size: 0.7rem;
    color: #ff0000;
  }

  .capacity-details {
    padding-top: 30px;
    padding-bottom: 20px;

    .capacity {
      font-size: 2.3rem;
      font-weight: 600;
      line-height: 22px;
      text-align: left;
      color: #1b1c20;
    }

    .amount {
      font-size: 0.9rem;
      font-weight: 500;
      line-height: 22px;
      text-align: right;
      color: #1b1c20;
    }
    .due-date {
      font-size: 0.7rem;
      font-weight: 500;
      line-height: 21px;
      text-align: right;
      color: #0e1f33;
    }
  }
  .show-more {
    color: #e873a8;
    font-size: 0.8rem;
    margin-top: 15px;
    transition: all 0.5s ease-in-out;
    span {
      cursor: pointer;
    }
  }
  .btn-default {
    border-radius: 32px;
  }
}

.delivery-details {
  .title {
    font-size: 0.93rem !important;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #1b1c20;
  }

  .value {
    font-size: 0.77rem !important;
    font-weight: 500;
    line-height: 21px;
    text-align: right;
    color: #000000;
  }
}

.completed-installation-form-info {
  margin-bottom: 20px;

  .title {
    font-size: 0.93rem;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #1b1c20;
  }

  .details {
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #82858a;
  }
}

.installation-progress {
  .title {
    font-size: 0.93rem;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #1b1c20;
  }

  .details {
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #82858a;
  }

  .btn-default {
    border-radius: 32px;
  }
}

.approval-card {
  .title {
    font-size: 0.93rem !important;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #1b1c20;
  }

  .btn-default {
    border-radius: 32px;
  }

  .reject-btn {
    width: 81px;
    height: 35px;
    border-radius: 50px;
    border: 1px solid #fc6fbb;
    color: #fc6fbb;
  }
}

.installation-progress {
}

.rejection-form {
  label {
    font-size: 0.8rem;
    margin-bottom: 10px;
  }
  textarea {
    height: 100px;
  }
  .btn-reject-001 {
    border-radius: 32px;
  }
}

.transaction {
  thead {
    th {
      font-size: 0.82rem;
      font-weight: bold !important;
    }
  }

  tbody {
    font-size: 0.78rem;
  }
}
